import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '@/views/Homepage.vue'
import login from '../views/Login.vue'
import forgotPassword from '@/views/forgotPassword.vue'
import forgotPasswordSuccess from '@/views/forgotPasswordSuccess.vue'


Vue.use(VueRouter)

const routes = [{

    path: '/',
    name: 'login',
    component: login
},
{ path: '/forgotpassword', name: 'forgot', component: forgotPassword },
{ path: '/forgotPasswordSuccess', name: 'forgotSuccess', component: forgotPasswordSuccess },
{
    path: '/ExportVat',
    name: 'ExportVat',
    component: () =>
        import('@/views/ExportExcel/ExportVat.vue')
},
{
    path: '/invc',
    name: 'invc',
    component: () =>
        import('@/views/ExportExcel/ExportInvoiceeee.vue')
},
{
    path: '/SelectYear',
    name: 'SelectYear',
    component: () =>
        import('@/views/SelectYear')
},
{
    path: '/Home',
    component: Homepage,
    children: [
        {
            path: '/ManageForm1',
            name: 'ManageForm1',
            component: () =>
                import('@/views/ManageForm1')
        },
        {
            path: '/Form1',
            name: 'Form1',
            component: () =>
                import('@/components/ManageForm1/CreateForm1.vue')
        },
        {
            path: '/EditForm1',
            name: 'EditForm1',
            component: () =>
                import('@/components/ManageForm1/EditForm1.vue')
        },
        {
            path: '/ViewForm1',
            name: 'ViewForm1',
            component: () =>
                import('@/components/ManageForm1/ViewForm1.vue')
        },
        {
            path: '/ManageForm2',
            name: 'ManageForm2',
            component: () =>
                import('@/views/ManageForm2')
        },
        {
            path: '/Form2',
            name: 'Form2',
            component: () =>
                import('@/components/ManageForm2/CreateForm2.vue')
        },
        {
            path: '/EditForm2',
            name: 'EditForm2',
            component: () =>
                import('@/components/ManageForm2/EditForm2.vue')
        },
        {
            path: '/ViewForm2',
            name: 'ViewForm2',
            component: () =>
                import('@/components/ManageForm2/ViewForm2.vue')
        },
        {
            path: '/ManageForm3',
            name: 'ManageForm3',
            component: () =>
                import('@/views/ManageForm3')
        },
        {
            path: '/Form3',
            name: 'Form3',
            component: () =>
                import('@/components/ManageForm3/CreateForm3.vue')
        },
        {
            path: '/EditForm3',
            name: 'EditForm3',
            component: () =>
                import('@/components/ManageForm3/EditForm3.vue')
        },
        {
            path: '/ViewForm3',
            name: 'ViewForm3',
            component: () =>
                import('@/components/ManageForm3/ViewForm3.vue')
        },
        {
            path: '/ManageForm4',
            name: 'ManageForm4',
            component: () =>
                import('@/views/ManageForm4')
        },
        {
            path: '/Form4',
            name: 'Form4',
            component: () =>
                import('@/components/ManageForm4/CreateForm4.vue')
        },
        {
            path: '/EditForm4',
            name: 'EditForm4',
            component: () =>
                import('@/components/ManageForm4/EditForm4.vue')
        },
        {
            path: '/ViewForm4',
            name: 'ViewForm4',
            component: () =>
                import('@/components/ManageForm4/ViewForm4.vue')
        },
        {
            path: '/ManageForm5',
            name: 'ManageForm5',
            component: () =>
                import('@/views/ManageForm5')
        },
        {
            path: '/Form5',
            name: 'Form5',
            component: () =>
                import('@/components/ManageForm5/CreateForm5.vue')
        },
        {
            path: '/EditForm5',
            name: 'EditForm5',
            component: () =>
                import('@/components/ManageForm5/EditForm5.vue')
        },
        {
            path: '/ViewForm5',
            name: 'ViewForm5',
            component: () =>
                import('@/components/ManageForm5/ViewForm5.vue')
        },
        {
            path: '/ManageForm6',
            name: 'ManageForm6',
            component: () =>
                import('@/views/ManageForm6')
        },
        {
            path: '/Form6',
            name: 'Form6',
            component: () =>
                import('@/components/ManageForm6/CreateForm6.vue')
        },
        {
            path: '/EditForm6',
            name: 'EditForm6',
            component: () =>
                import('@/components/ManageForm6/EditForm6.vue')
        },
        {
            path: '/ViewForm6',
            name: 'ViewForm6',
            component: () =>
                import('@/components/ManageForm6/ViewForm6.vue')
        },
        {
            path: '/ManageForm7',
            name: 'ManageForm7',
            component: () =>
                import('@/views/ManageForm7')
        },
        {
            path: '/Form7',
            name: 'Form7',
            component: () =>
                import('@/components/ManageForm7/CreateForm7.vue')
        },
        {
            path: '/EditForm7',
            name: 'EditForm7',
            component: () =>
                import('@/components/ManageForm7/EditForm7.vue')
        },
        {
            path: '/ViewForm7',
            name: 'ViewForm7',
            component: () =>
                import('@/components/ManageForm7/ViewForm7.vue')
        },
        {
            path: '/ManageForm8',
            name: 'ManageForm8',
            component: () =>
                import('@/views/ManageForm8')
        },
        {
            path: '/Form8',
            name: 'Form8',
            component: () =>
                import('@/components/ManageForm8/CreateForm8.vue')
        },
        {
            path: '/EditForm8',
            name: 'EditForm8',
            component: () =>
                import('@/components/ManageForm8/EditForm8.vue')
        },
        {
            path: '/ViewForm8',
            name: 'ViewForm8',
            component: () =>
                import('@/components/ManageForm8/ViewForm8.vue')
        },
        {
            path: '/ManageForm9',
            name: 'ManageForm9',
            component: () =>
                import('@/views/ManageForm9')
        },
        {
            path: '/Form9',
            name: 'Form9',
            component: () =>
                import('@/components/ManageForm9/CreateForm9.vue')
        },
        {
            path: '/EditForm9',
            name: 'EditForm9',
            component: () =>
                import('@/components/ManageForm9/EditForm9.vue')
        },
        {
            path: '/ViewForm9',
            name: 'ViewForm9',
            component: () =>
                import('@/components/ManageForm9/ViewForm9.vue')
        },
        {
            path: '/ManageForm10',
            name: 'ManageForm10',
            component: () =>
                import('@/views/ManageForm10')
        },
        {
            path: '/Form10',
            name: 'Form10',
            component: () =>
                import('@/components/ManageForm10/CreateForm10.vue')
        },
        {
            path: '/EditForm10',
            name: 'EditForm10',
            component: () =>
                import('@/components/ManageForm10/EditForm10.vue')
        },
        {
            path: '/ViewForm10',
            name: 'ViewForm10',
            component: () =>
                import('@/components/ManageForm10/ViewForm10.vue')
        },
        {
            path: '/ManageForm11',
            name: 'ManageForm11',
            component: () =>
                import('@/views/ManageForm11')
        },
        {
            path: '/Form11',
            name: 'Form11',
            component: () =>
                import('@/components/ManageForm11/CreateForm11.vue')
        },
        {
            path: '/EditForm11',
            name: 'EditForm11',
            component: () =>
                import('@/components/ManageForm11/EditForm11.vue')
        },
        {
            path: '/ViewForm11',
            name: 'ViewForm11',
            component: () =>
                import('@/components/ManageForm11/ViewForm11.vue')
        },


        // {
        //     path: '/ManageType',
        //     name: 'ManageType',
        //     component: () =>
        //         import('@/views/ManageType')
        // },
        // {
        //     path: '/CreateType',
        //     name: 'CreateType',
        //     component: () =>
        //         import('@/components/Type/CreateType.vue')
        // },
        // {
        //     path: '/EditType',
        //     name: 'EditType',
        //     component: () =>
        //         import('@/components/Type/EditType.vue')
        // },
        // {
        //     path: '/ViewType',
        //     name: 'ViewType',
        //     component: () =>
        //         import('@/components/Type/ViewType.vue')
        // },
        // {
        //     path: '/ManageBrand',
        //     name: 'ManageBrand',
        //     component: () =>
        //         import('@/views/ManageBrand')
        // },
        // {
        //     path: '/CreateBrand',
        //     name: 'CreateBrand',
        //     component: () =>
        //         import('@/components/Brand/CreateBrand.vue')
        // },
        // {
        //     path: '/EditBrand',
        //     name: 'EditBrand',
        //     component: () =>
        //         import('@/components/Brand/EditBrand.vue')
        // },
        // {
        //     path: '/ViewBrand',
        //     name: 'ViewBrand',
        //     component: () =>
        //         import('@/components/Brand/ViewBrand.vue')
        // },
        // {
        //     path: '/CreateProduct',
        //     name: 'CreateProduct',
        //     component: () =>
        //         import('@/components/Product/CreateProduct.vue')
        // },
        // {
        //     path: '/ManageProduct',
        //     name: 'ManageProduct',
        //     component: () =>
        //         import('@/views/ManageProduct')
        // },
        // {
        //     path: '/CreateProduct',
        //     name: 'CreateProduct',
        //     component: () =>
        //         import('@/components/Product/CreateProduct.vue')
        // },
        // {
        //     path: '/viewProduct',
        //     name: 'viewProduct',
        //     component: () =>
        //         import('@/components/Product/viewProduct.vue')
        // },
        // {
        //     path: '/EditProduct',
        //     name: 'EditProduct',
        //     component: () =>
        //         import('@/components/Product/EditProduct.vue')
        // },
        {
            path: '/ManageUser',
            name: 'ManageUser',
            component: () =>
                import('@/views/ManageUser')
        },
        {
            path: '/viewUser',
            name: 'viewUser',
            component: () =>
                import('@/components/User/viewUser.vue')
        }, 
        {
            path: '/createUser',
            name: 'createUser',
            component: () =>
                import('@/components/User/createUser.vue')
        }, 
        {
            path: '/editUser',
            name: 'editUser',
            component: () =>
                import('@/components/User/editUser.vue')
        }, 
        // {
        //     path: '/ManageOrder',
        //     name: 'ManageOrder',
        //     component: () =>
        //         import('@/views/ManageOrder')
        // },
        // {
        //     path: '/viewOrder',
        //     name: 'viewOrder',
        //     component: () =>
        //         import('@/components/Order/viewOrder.vue')
        // },
        // {
        //     path: '/EditOrder',
        //     name: 'EditOrder',
        //     component: () =>
        //         import('@/components/Order/EditOrder.vue')
        // },

        // {
        //     path: '/ManageCompany',
        //     name: 'ManageCompany',
        //     component: () =>
        //         import('@/views/ManageCompany')
        // },
        // {
        //     path: '/CreateCompany',
        //     name: 'CreateCompany',
        //     component: () =>
        //         import('@/components/Company/CreateCompany.vue')
        // },
        // {
        //     path: '/EditCompany',
        //     name: 'EditCompany',
        //     component: () =>
        //         import('@/components/Company/EditCompany.vue')
        // },
        // {
        //     path: '/ViewCompany',
        //     name: 'ViewCompany',
        //     component: () =>
        //         import('@/components/Company/ViewCompany.vue')
        // },
        // {
        //     path: '/Dashboard',
        //     name: 'Dashboard',
        //     component: () =>
        //         import('@/views/Dashboard')
        // },
        {
            path: '/ManageBanner',
            name: 'ManageBanner',
            component: () =>
                import('@/views/ManageBanner')
        },
        {
            path: '/CreateBanner',
            name: 'CreateBanner',
            component: () =>
                import('@/components/Banner/CreateBanner.vue')
        },
        {
            path: '/EditBanner',
            name: 'EditBanner',
            component: () =>
                import('@/components/Banner/EditBanner.vue')
        },
        {
            path: '/ViewBanner',
            name: 'ViewBanner',
            component: () =>
                import('@/components/Banner/ViewBanner.vue')
        },
        // {
        //     path: '/ManageNews',
        //     name: 'ManageNews',
        //     component: () =>
        //         import('@/views/ManageNews')
        // },
        // {
        //     path: '/CreateNews',
        //     name: 'CreateNews',
        //     component: () =>
        //         import('@/components/News/CreateNews.vue')
        // },
        // {
        //     path: '/ViewNews',
        //     name: 'ViewNews',
        //     component: () =>
        //         import('@/components/News/ViewNews.vue')
        // },
        // {
        //     path: '/EditNews',
        //     name: 'EditNews',
        //     component: () =>
        //         import('@/components/News/EditNews.vue')
        // },
        // {
        //     path: '/ManageCoupon',
        //     name: 'ManageCoupon',
        //     component: () =>
        //         import('@/views/ManageCoupon')
        // },
        // {
        //     path: '/CreateCoupon',
        //     name: 'CreateCoupon',
        //     component: () =>
        //         import('@/components/Coupon/CreateCoupon.vue')
        // },
        // {
        //     path: '/ViewCoupon',
        //     name: 'ViewCoupon',
        //     component: () =>
        //         import('@/components/Coupon/ViewCoupon.vue')
        // },
        // {
        //     path: '/EditCoupon',
        //     name: 'EditCoupon',
        //     component: () =>
        //         import('@/components/Coupon/EditCoupon.vue')
        // },
        // {
        //     path: '/ManageVoucher',
        //     name: 'ManageVoucher',
        //     component: () =>
        //         import('@/views/ManageVoucher')
        // },
        // {
        //     path: '/CreateVoucher',
        //     name: 'CreateVoucher',
        //     component: () =>
        //         import('@/components/Voucher/CreateVoucher.vue')
        // },
        // {
        //     path: '/ViewVoucher',
        //     name: 'ViewVoucher',
        //     component: () =>
        //         import('@/components/Voucher/ViewVoucher.vue')
        // },
        // {
        //     path: '/EditVoucher',
        //     name: 'EditVoucher',
        //     component: () =>
        //         import('@/components/Voucher/EditVoucher.vue')
        // },
        {
            path: '/ManageAnnouncement',
            name: 'ManageAnnouncement',
            component: () =>
                import('@/views/ManageAnnouncement')
        },
        {
            path: '/CreateAnnouncement',
            name: 'CreateAnnouncement',
            component: () =>
                import('@/components/Announcement/CreateAnnouncement.vue')
        },
        {
            path: '/ViewAnnouncement',
            name: 'ViewAnnouncement',
            component: () =>
                import('@/components/Announcement/ViewAnnouncement.vue')
        },
        {
            path: '/EditAnnouncement',
            name: 'EditAnnouncement',
            component: () =>
                import('@/components/Announcement/EditAnnouncement.vue')
        },
        // {
        //     path: '/Report1',
        //     name: 'Report1',
        //     component: () =>
        //         import('@/components/Report/Report1.vue')
        // },
    ]
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router